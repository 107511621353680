<script>
  import { v4 as uuid } from "uuid";

  export let cssClass = "col";
  export let type = "text";
  export let name;
  export let placeholder;
  export let pattern;
  export let label;

  let id = uuid();
</script>

<div class={cssClass}>
  <label for={id}>{label}</label>
  {#if pattern != undefined}
    <input
      class="form-control"
      {type}
      {name}
      {placeholder}
      {pattern}
      required />
  {:else}
    <input class="form-control" {type} {name} {placeholder} />
  {/if}
  <div class="invalid-feedback">Please provide a valid {label}.</div>
</div>
