let text = "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."

const faqData = [{
    anchor: "collapse_1",
    question: "What is Mamasara?",
    answer: text
}, {
    anchor: "collapse_2",
    question: "What is Lorem Ipsum Two?",
    answer: text
}, {
    anchor: "collapse_3",
    question: "What is Lorem Ipsum Three?",
    answer: text
}, {
    anchor: "collapse_4",
    question: "What is Lorem Ipsum Four?",
    answer: text
}, {
    anchor: "collapse_5",
    question: "What is Lorem Ipsum Five?",
    answer: text
},];

export default faqData;