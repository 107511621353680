<script>
  import HamburgerButton from "../svg/HamburgerButton.svelte";
  import MenuTop from "../builder/layout/MenuTop.svelte";
  import MenuAside from "../builder/layout/MenuAside.svelte";
  import Setup from "../builder/Setup.svelte";
  import Name from "../builder/Name.svelte";
  import Download from "../builder/Download.svelte";
  import Footer from "../builder/layout/Footer.svelte";
  import CookiePolicyModal from "./../components/CookiePolicyModal.svelte";
  import { onMount } from "svelte";
  import wrapApp from "./../scripts/mamasara";

  export let view;

  onMount(async () => {
    wrapApp.addons();
    wrapApp.search();
    wrapApp.menu();
    wrapApp.bootstrap_active();
    wrapApp.validate_condition();
    wrapApp.custom_bar();
  });
</script>

<div class="modal-menu container-fluid min-vh-100 theme-app">
  <div class="col-12 min-vh-100">
    <div class="d-lg-none">
      <div class="row">
        <div class="col cont-aside">
          <div class="cont-menu">
            <button
              class="menu opened close m-0"
              onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))"
              aria-label="Main Menu"
              aria-expanded="true">
              <HamburgerButton />
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="cont-top-data d-flex" style="border: 0;">
            <div class="col text-center align-self-center">
              <p>
                <img src="/image/logo-mamasara-withe.png" alt="Mamasara" />
              </p>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/faq">Faq</a></li>
                <li>
                  <a href="mailto:mamasara@escalableapps.com">Comments</a>
                </li>
              </ul>
              <p>© 2021 Escalable Apps EIRL</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-xl-1 col-lg-2 min-vh-100 cont-aside">
          <div class="cont-menu">
            <button
              class="menu opened close"
              onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))"
              aria-label="Main Menu"
              aria-expanded="true">
              <HamburgerButton />
            </button>
          </div>
        </div>
        <div class="col min-vh-100">
          <div class="cont-top-data d-flex">
            <div class="col text-center align-self-center">
              <p>
                <img src="/image/logo-mamasara-withe.png" alt="Mamasara" />
              </p>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/faq">Faq</a></li>
                <li>
                  <a href="mailto:mamasara@escalableapps.com">Comments</a>
                </li>
              </ul>
              <p>© 2021 Escalable Apps EIRL</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="container-fluid min-vh-100 d-flex justify-content-center center-block theme-app">
  <div class="col-12 min-vh-100">
    <div class="row">
      <div class="col-12">
        <MenuTop />
      </div>
    </div>
    <div class="row">
      <MenuAside />
      <div class="col min-vh-100">
        {#if view === "setup"}
          <Setup />
        {:else if view === "name"}
          <Name />
        {:else}
          <Download />
        {/if}
        <Footer {view} />
      </div>
    </div>
  </div>
</div>
<CookiePolicyModal />
