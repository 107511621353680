<script>
  import CookiesPolicy from "../policies/CookiesPolicy.svelte";
  import { onMount } from "svelte";
  import Cookies from "js-cookie";

  onMount(async () => {
    var cookieAlert = document.querySelector(".cookiealert");
    if (!cookieAlert) {
      return;
    }

    cookieAlert.offsetHeight;
    if (Cookies.get("acceptCookies") == undefined) {
      cookieAlert.classList.add("show");
    }
    document
      .querySelectorAll(".acceptcookies")
      .forEach(function (button) {
        button.addEventListener("click", function () {
          Cookies.set("acceptCookies", "true", { expires: 365 });
          cookieAlert.classList.remove("show");
        });
      });
  });
</script>

<div class="alert text-center cookiealert" role="alert">
  <b>We use cookies</b> and other tracking technologies to improve your browsing
  experience on our website, to show you personalized content and targeted ads,
  to analyze our website traffic, and to understand where our visitors are
  coming from.
  <a href="#cookies" data-toggle="modal" data-target="#cookies">Learn more</a>
  <button
    id="acceptcookies-modal"
    class="btn btn-primary btn-sm acceptcookies"
    type="button">I agree</button>
</div>
<!-- Modal-->
<div
  class="modal fade"
  id="cookies"
  tabindex="-1"
  aria-labelledby="cookiePolicyLabel"
  aria-hidden="true">
  <div
    class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="cookiePolicyLabel">Cookies Policy</h3>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <CookiesPolicy />
      </div>
      <div class="modal-footer">
        <button
          id="acceptcookies-dialog"
          class="btn btn-primary acceptcookies"
          type="button"
          data-dismiss="modal">I agree</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
          >Close</button>
      </div>
    </div>
  </div>
</div>
