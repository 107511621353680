<script>
  import CookiesPolicy from "./../policies/CookiesPolicy.svelte";
  import TermsAndConditions from "../policies/TermsAndConditions.svelte";
  import InfoFooter from "../components/InfoFooter.svelte";
  import CookiePolicyModal from "./../components/CookiePolicyModal.svelte";
  import { onMount } from "svelte";
  import wrapApp from "../scripts/mamasara";

  export let policy;

  onMount(async () => {
    wrapApp.addons();
    wrapApp.search();
    wrapApp.menu();
    wrapApp.bootstrap_active();
    wrapApp.validate_condition();
    wrapApp.custom_bar();
  });
</script>

<div class="container-fluid theme-landing">
  <section class="container cont-default">
    <div class="row">
      <div class="col-12 mb-3">
        <a class="button" href="/" title="Back"> <span>Back</span></a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        {#if policy === "cookies"}
          <h1>Cookies Policy</h1>
          <CookiesPolicy />
        {:else if policy === "terms"}
          <h1>Terms and Conditions</h1>
          <TermsAndConditions />
        {:else}
          <h1>Cookies Policy</h1>
          <CookiesPolicy />
        {/if}
      </div>
    </div>
  </section>
  <InfoFooter />
</div>
<CookiePolicyModal />
