<script>
    import { Router, Route } from 'svelte-routing';
    import Landing from './routes/Landing.svelte';
    import Faq from './routes/Faq.svelte';
    import Policy from './routes/Policy.svelte';
    import Builder from './routes/Builder.svelte';

    export let url = '';
</script>

<Router {url}>
    <Route path="/"><Landing /></Route>
    <Route path="/faq"><Faq /></Route>
    <Route path="/cookies"><Policy policy="cookies" /></Route>
    <Route path="/privacy"><Policy /></Route>
    <Route path="/terms"><Policy policy="terms" /></Route>
    <Route path="/eula"><Policy /></Route>
    <Route path="/setup"><Builder view="setup" /></Route>
    <Route path="/name"><Builder view="name" /></Route>
    <Route path="/download"><Builder view="download" /></Route>
</Router>
