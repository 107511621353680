<script>
  export let data;
  export let index;
</script>

{#if index === 0}
  <li>
    <a data-toggle="collapse" href="#{data.anchor}" aria-expanded="true"
      >{data.question}</a>
  </li>
{:else}
  <li>
    <a data-toggle="collapse" href="#{data.anchor}" aria-expanded="false"
      >{data.question}</a>
  </li>
{/if}
