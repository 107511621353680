<script>
  import HexagonOne from "../svg/HexagonOne.svelte";
  import HexagonTwo from "../svg/HexagonTwo.svelte";
  import SearchIcon from "../svg/SearchIcon.svelte";
  import RadioButton from "./forms/RadioButton.svelte";
  import Input from "./forms/Input.svelte";
  import CheckBoxSeachable from "./forms/CheckBoxSeachable.svelte";
  import RadioButtonSearchable from "./forms/RadioButtonSearchable.svelte";
  import TermsAndConditionsModal from "./components/TermsAndConditionsModal.svelte";

  import formConfig from "../scripts/setup-config";
</script>

<div class="cont-top-data">
  <div class="custom-scrollbar-css pr-2">
    <form id="formStepOne" class="row">
      <div class="col-xl-6 col-lg-12 align-self-top">
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  <div class="hexagon"><span>1</span></div>
                  <span>Project SetUp</span>
                  <HexagonOne />
                </button>
              </h2>
            </div>
            <div
              class="collapse show"
              id="collapseOne"
              aria-labelledby="headingOne"
              data-parent="#accordionExample">
              <div class="card-body">
                <h3>Project</h3>
                {#each formConfig.projectInformation.buildAutomation as item}
                  <RadioButton {...item} />
                {/each}
                <h3>Language</h3>
                {#each formConfig.projectInformation.languaje as item}
                  <RadioButton {...item} />
                {/each}
                <h3>Spring Boot</h3>
                {#each formConfig.projectInformation.springBootVersion as item}
                  <RadioButton {...item} />
                {/each}
                <h3>Project Metadata</h3>
                <div class="row from-in">
                  <Input
                    {...formConfig.projectInformation.projectMetadata.group} />
                  <Input
                    {...formConfig.projectInformation.projectMetadata
                      .artifact} />
                </div>
                <div class="row from-in">
                  <Input
                    {...formConfig.projectInformation.projectMetadata.name} />
                  <Input
                    {...formConfig.projectInformation.projectMetadata
                      .description} />
                </div>
                <div class="row from-in">
                  <Input
                    {...formConfig.projectInformation.projectMetadata
                      .packageName} />
                </div>
                <h3>Packaging</h3>
                {#each formConfig.projectInformation.packaging as item}
                  <RadioButton {...item} />
                {/each}
                <h3>Java</h3>
                {#each formConfig.projectInformation.javaVersion as item}
                  <RadioButton {...item} />
                {/each}
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  <div class="hexagon"><span>2</span></div>
                  <span>Datasource Setup</span>
                  <HexagonTwo />
                </button>
              </h2>
            </div>
            <div
              class="collapse"
              id="collapseTwo"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample">
              <div class="card-body">
                <div class="row from-in select-type-bd">
                  <div class="col">
                    {#each formConfig.jdbcSetting.databaseVendor as item}
                      <RadioButton {...item} />
                    {/each}
                  </div>
                </div>
                <div class="row from-in">
                  <Input {...formConfig.jdbcSetting.host} />
                  <Input {...formConfig.jdbcSetting.port} />
                </div>
                <div class="row from-in">
                  <Input {...formConfig.jdbcSetting.database} />
                  <Input {...formConfig.jdbcSetting.schema} />
                </div>
                <div class="row from-in">
                  <Input {...formConfig.jdbcSetting.user} />
                  <Input {...formConfig.jdbcSetting.password} />
                </div>
                <div class="row from-in">
                  <div class="col">
                    <label
                      for="inputGroupFile01"
                      data-toggle="tooltip"
                      title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. "
                      >DDL Script (?)</label>
                    <div class="input-group mt-3">
                      <div class="custom-file">
                        <input
                          class="custom-file-input"
                          id="inputGroupFile01"
                          name="jdbcSetting[ddlScript]"
                          type="file"
                          aria-describedby="inputGroupFileAddon01" />
                        <label
                          class="custom-file-label m-0"
                          for="inputGroupFile01">Choose file</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row from-in">
                  <div class="col">
                    <br />
                    <p>
                      We do not store the connection to your database. However,
                      we advise you to use a development database when using
                      this service.
                    </p>
                    <p>
                      Why do we need to connect to your database? <a
                        href="/faq#why_connect">see in faq</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 align-self-top border-left search-addons">
        <div class="row">
          <div class="col">
            <h5>Developper Tools</h5>
          </div>
        </div>
        <div class="row cont-search-input">
          <div class="col">
            <input
              class="form-control"
              id="search"
              type="text"
              placeholder="Search" />
            <button class="btn m-0 p-0" type="submit">
              <SearchIcon />
            </button>
          </div>
        </div>
        <div class="row cont-addons">
          <div class="col">
            {#each formConfig.dependencies as dependency}
              <h4>{dependency.title}</h4>
              <ul class="p-0">
                {#each dependency.options as item}
                  {#if item.type === "radiobutton"}
                    <RadioButtonSearchable {...item} />
                  {:else}
                    <CheckBoxSeachable {...item} />
                  {/if}
                {/each}
              </ul>
            {/each}
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<TermsAndConditionsModal />
