const data = {
    tests: true,
    projectInformation: {
        buildAutomation: "MAVEN_SINGLE",
        languaje: "JAVA",
        springBootVersion: "v2_4",
        packaging: "JAR",
        javaVersion: "v8",
        projectMetadata: {
            group: "com.escalableapps.cmdb.baseapp",
            artifact: "crud-base-app",
            name: "Crud Base App",
            description: "Crud Base App project for Spring Boot",
            service: "Template",
            packageName: "com.escalableapps.cmdb.baseapp",
            appName: "CrudBaseApp",
        },
    },
    jdbcSetting: {
        databaseVendor: "MYSQL",
        host: "localhost",
        port: 3306,
        schema: "crud_base_db",
        database: "crud_base_db",
        username: "root",
        password: "password",
    },
    schema: {
        catalog: "crud_base_db",
        schema: "crud_base_db",
        tables: [
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "bar",
                sign: "693b9f14984c895dfecf24dc2b04ed7b0546f048db478cdfd78388b604b039da",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "barId",
                        propertyDescription: "Description for barId",
                        propertyRestName: "bar-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_char",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "char",
                        maxLength: 1,
                        propertyName: "barChar",
                        propertyDescription: "Description for barChar",
                        propertyRestName: "bar-char",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_varchar",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "varchar",
                        maxLength: 100,
                        propertyName: "barVarchar",
                        propertyDescription: "Description for barVarchar",
                        propertyRestName: "bar-varchar",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_text",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "text",
                        maxLength: 65535,
                        propertyName: "barText",
                        propertyDescription: "Description for barText",
                        propertyRestName: "bar-text",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_smallint",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "smallint",
                        propertyName: "barSmallint",
                        propertyDescription: "Description for barSmallint",
                        propertyRestName: "bar-smallint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_integer",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "barInteger",
                        propertyDescription: "Description for barInteger",
                        propertyRestName: "bar-integer",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_bigint",
                        ordinal: 7,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "bigint",
                        propertyName: "barBigint",
                        propertyDescription: "Description for barBigint",
                        propertyRestName: "bar-bigint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_real",
                        ordinal: 8,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "double",
                        propertyName: "barReal",
                        propertyDescription: "Description for barReal",
                        propertyRestName: "bar-real",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_double",
                        ordinal: 9,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "double",
                        propertyName: "barDouble",
                        propertyDescription: "Description for barDouble",
                        propertyRestName: "bar-double",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_decimal",
                        ordinal: 10,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "decimal",
                        numericPrecision: 10,
                        numericScale: 0,
                        propertyName: "barDecimal",
                        propertyDescription: "Description for barDecimal",
                        propertyRestName: "bar-decimal",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_boolean",
                        ordinal: 11,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "tinyint(1)",
                        propertyName: "barBoolean",
                        propertyDescription: "Description for barBoolean",
                        propertyRestName: "bar-boolean",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_date",
                        ordinal: 12,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "date",
                        propertyName: "barDate",
                        propertyDescription: "Description for barDate",
                        propertyRestName: "bar-date",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bar",
                        column: "bar_timestamp",
                        ordinal: 13,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "timestamp",
                        timestampPrecision: 0,
                        propertyName: "barTimestamp",
                        propertyDescription: "Description for barTimestamp",
                        propertyRestName: "bar-timestamp",
                    },
                ],
                entityName: "Bar",
                entityDescription: "Description for Bar",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "bigints",
                sign: "f9ea5fcb884b2ef59128117e5109740e7e2f866677fe92234dc76a83320f3a90",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bigints",
                        column: "bigints_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "bigint",
                        propertyName: "bigintsId",
                        propertyDescription: "Description for bigintsId",
                        propertyRestName: "bigints-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bigints",
                        column: "bigints_int8",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "bigint",
                        propertyName: "bigintsInt8",
                        propertyDescription: "Description for bigintsInt8",
                        propertyRestName: "bigints-int8",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "bigints",
                        column: "bigints_bigint",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "bigint",
                        propertyName: "bigintsBigint",
                        propertyDescription: "Description for bigintsBigint",
                        propertyRestName: "bigints-bigint",
                    },
                ],
                entityName: "Bigints",
                entityDescription: "Description for Bigints",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "dates",
                sign: "35ae8fe1e3b43dc60e6b4eb7488462dec2a4a340b52e65643510e6aaab25296e",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates",
                        column: "dates_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "datesId",
                        propertyDescription: "Description for datesId",
                        propertyRestName: "dates-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates",
                        column: "dates_date",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "date",
                        propertyName: "datesDate",
                        propertyDescription: "Description for datesDate",
                        propertyRestName: "dates-date",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates",
                        column: "dates_timestamp",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "timestamp",
                        timestampPrecision: 0,
                        propertyName: "datesTimestamp",
                        propertyDescription: "Description for datesTimestamp",
                        propertyRestName: "dates-timestamp",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates",
                        column: "dates_datetime",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "datetime",
                        timestampPrecision: 0,
                        propertyName: "datesDatetime",
                        propertyDescription: "Description for datesDatetime",
                        propertyRestName: "dates-datetime",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates",
                        column: "dates_time",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "time",
                        propertyName: "datesTime",
                        propertyDescription: "Description for datesTime",
                        propertyRestName: "dates-time",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates",
                        column: "dates_year",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "year",
                        propertyName: "datesYear",
                        propertyDescription: "Description for datesYear",
                        propertyRestName: "dates-year",
                    },
                ],
                entityName: "Dates",
                entityDescription: "Description for Dates",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "dates_n",
                sign: "527513d1c7e2439ad19395e6764fa7430b7fff67b7828c9d9f47d48db10c8000",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates_n",
                        column: "dates_n_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "datesNId",
                        propertyDescription: "Description for datesNId",
                        propertyRestName: "dates-n-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates_n",
                        column: "dates_n_timestamp",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "timestamp",
                        timestampPrecision: 3,
                        propertyName: "datesNTimestamp",
                        propertyDescription: "Description for datesNTimestamp",
                        propertyRestName: "dates-n-timestamp",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates_n",
                        column: "dates_n_datetime",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "datetime",
                        timestampPrecision: 3,
                        propertyName: "datesNDatetime",
                        propertyDescription: "Description for datesNDatetime",
                        propertyRestName: "dates-n-datetime",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates_n",
                        column: "dates_n_time",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "time",
                        propertyName: "datesNTime",
                        propertyDescription: "Description for datesNTime",
                        propertyRestName: "dates-n-time",
                    },
                ],
                entityName: "DatesN",
                entityDescription: "Description for DatesN",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "dates_z",
                sign: "a6576dc0221ce98dd793a6b9d7e82f9433f5d80b0a782ea7838fa861203a0c57",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates_z",
                        column: "dates_z_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "datesZId",
                        propertyDescription: "Description for datesZId",
                        propertyRestName: "dates-z-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates_z",
                        column: "dates_z_timestamp",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "timestamp",
                        timestampPrecision: 0,
                        propertyName: "datesZTimestamp",
                        propertyDescription: "Description for datesZTimestamp",
                        propertyRestName: "dates-z-timestamp",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates_z",
                        column: "dates_z_datetime",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "datetime",
                        timestampPrecision: 0,
                        propertyName: "datesZDatetime",
                        propertyDescription: "Description for datesZDatetime",
                        propertyRestName: "dates-z-datetime",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "dates_z",
                        column: "dates_z_time",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "time",
                        propertyName: "datesZTime",
                        propertyDescription: "Description for datesZTime",
                        propertyRestName: "dates-z-time",
                    },
                ],
                entityName: "DatesZ",
                entityDescription: "Description for DatesZ",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "decimals",
                sign: "71ed6c8cf206799df70bc6c7649826a1b96a354bff52fb64efc7c8ae572576df",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals",
                        column: "decimals_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "decimalsId",
                        propertyDescription: "Description for decimalsId",
                        propertyRestName: "decimals-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals",
                        column: "decimals_fixed",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 10,
                        numericScale: 0,
                        propertyName: "decimalsFixed",
                        propertyDescription: "Description for decimalsFixed",
                        propertyRestName: "decimals-fixed",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals",
                        column: "decimals_numeric",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 10,
                        numericScale: 0,
                        propertyName: "decimalsNumeric",
                        propertyDescription: "Description for decimalsNumeric",
                        propertyRestName: "decimals-numeric",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals",
                        column: "decimals_decimal",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 10,
                        numericScale: 0,
                        propertyName: "decimalsDecimal",
                        propertyDescription: "Description for decimalsDecimal",
                        propertyRestName: "decimals-decimal",
                    },
                ],
                entityName: "Decimals",
                entityDescription: "Description for Decimals",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "decimals_n",
                sign: "10e95096746fa518763a191ceb5efcc4a4d96420fd802c0336c49614f11243ae",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_n_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "decimalsNId",
                        propertyDescription: "Description for decimalsNId",
                        propertyRestName: "decimals-n-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_one",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 1,
                        numericScale: 0,
                        propertyName: "decimalsOne",
                        propertyDescription: "Description for decimalsOne",
                        propertyRestName: "decimals-one",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_two",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 2,
                        numericScale: 0,
                        propertyName: "decimalsTwo",
                        propertyDescription: "Description for decimalsTwo",
                        propertyRestName: "decimals-two",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_three",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 3,
                        numericScale: 0,
                        propertyName: "decimalsThree",
                        propertyDescription: "Description for decimalsThree",
                        propertyRestName: "decimals-three",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_four",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 4,
                        numericScale: 0,
                        propertyName: "decimalsFour",
                        propertyDescription: "Description for decimalsFour",
                        propertyRestName: "decimals-four",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_five",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 5,
                        numericScale: 0,
                        propertyName: "decimalsFive",
                        propertyDescription: "Description for decimalsFive",
                        propertyRestName: "decimals-five",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_six",
                        ordinal: 7,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 6,
                        numericScale: 0,
                        propertyName: "decimalsSix",
                        propertyDescription: "Description for decimalsSix",
                        propertyRestName: "decimals-six",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_seven",
                        ordinal: 8,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 7,
                        numericScale: 0,
                        propertyName: "decimalsSeven",
                        propertyDescription: "Description for decimalsSeven",
                        propertyRestName: "decimals-seven",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_eight",
                        ordinal: 9,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 8,
                        numericScale: 0,
                        propertyName: "decimalsEight",
                        propertyDescription: "Description for decimalsEight",
                        propertyRestName: "decimals-eight",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_nine",
                        ordinal: 10,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 9,
                        numericScale: 0,
                        propertyName: "decimalsNine",
                        propertyDescription: "Description for decimalsNine",
                        propertyRestName: "decimals-nine",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_ten",
                        ordinal: 11,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 10,
                        numericScale: 0,
                        propertyName: "decimalsTen",
                        propertyDescription: "Description for decimalsTen",
                        propertyRestName: "decimals-ten",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_eleven",
                        ordinal: 12,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 11,
                        numericScale: 0,
                        propertyName: "decimalsEleven",
                        propertyDescription: "Description for decimalsEleven",
                        propertyRestName: "decimals-eleven",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_twelve",
                        ordinal: 13,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 12,
                        numericScale: 0,
                        propertyName: "decimalsTwelve",
                        propertyDescription: "Description for decimalsTwelve",
                        propertyRestName: "decimals-twelve",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_thirteen",
                        ordinal: 14,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 13,
                        numericScale: 0,
                        propertyName: "decimalsThirteen",
                        propertyDescription: "Description for decimalsThirteen",
                        propertyRestName: "decimals-thirteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_fourteen",
                        ordinal: 15,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 14,
                        numericScale: 0,
                        propertyName: "decimalsFourteen",
                        propertyDescription: "Description for decimalsFourteen",
                        propertyRestName: "decimals-fourteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_fiveteen",
                        ordinal: 16,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 15,
                        numericScale: 0,
                        propertyName: "decimalsFiveteen",
                        propertyDescription: "Description for decimalsFiveteen",
                        propertyRestName: "decimals-fiveteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_sixteen",
                        ordinal: 17,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 16,
                        numericScale: 0,
                        propertyName: "decimalsSixteen",
                        propertyDescription: "Description for decimalsSixteen",
                        propertyRestName: "decimals-sixteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_seventeen",
                        ordinal: 18,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 17,
                        numericScale: 0,
                        propertyName: "decimalsSeventeen",
                        propertyDescription: "Description for decimalsSeventeen",
                        propertyRestName: "decimals-seventeen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_eigthteen",
                        ordinal: 19,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 18,
                        numericScale: 0,
                        propertyName: "decimalsEigthteen",
                        propertyDescription: "Description for decimalsEigthteen",
                        propertyRestName: "decimals-eigthteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_nineteen",
                        ordinal: 20,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 19,
                        numericScale: 0,
                        propertyName: "decimalsNineteen",
                        propertyDescription: "Description for decimalsNineteen",
                        propertyRestName: "decimals-nineteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_twenty",
                        ordinal: 21,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 20,
                        numericScale: 0,
                        propertyName: "decimalsTwenty",
                        propertyDescription: "Description for decimalsTwenty",
                        propertyRestName: "decimals-twenty",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_twentyone",
                        ordinal: 22,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 21,
                        numericScale: 0,
                        propertyName: "decimalsTwentyone",
                        propertyDescription: "Description for decimalsTwentyone",
                        propertyRestName: "decimals-twentyone",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n",
                        column: "decimals_twentytwo",
                        ordinal: 23,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 22,
                        numericScale: 0,
                        propertyName: "decimalsTwentytwo",
                        propertyDescription: "Description for decimalsTwentytwo",
                        propertyRestName: "decimals-twentytwo",
                    },
                ],
                entityName: "DecimalsN",
                entityDescription: "Description for DecimalsN",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "decimals_n_m",
                sign: "b1ec699434c15976c31bfb77458071681b0d828899bab3d572f60d9d3a03d2fe",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n_m",
                        column: "decimals_n_m_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "decimalsNMId",
                        propertyDescription: "Description for decimalsNMId",
                        propertyRestName: "decimals-n-m-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n_m",
                        column: "decimals_n_m_fixed",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 3,
                        numericScale: 1,
                        propertyName: "decimalsNMFixed",
                        propertyDescription: "Description for decimalsNMFixed",
                        propertyRestName: "decimals-n-m-fixed",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n_m",
                        column: "decimals_n_m_numeric",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 3,
                        numericScale: 1,
                        propertyName: "decimalsNMNumeric",
                        propertyDescription: "Description for decimalsNMNumeric",
                        propertyRestName: "decimals-n-m-numeric",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n_m",
                        column: "decimals_n_m_decimal",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 3,
                        numericScale: 1,
                        propertyName: "decimalsNMDecimal",
                        propertyDescription: "Description for decimalsNMDecimal",
                        propertyRestName: "decimals-n-m-decimal",
                    },
                ],
                entityName: "DecimalsNM",
                entityDescription: "Description for DecimalsNM",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "decimals_n_z",
                sign: "76b185f5b359f6ba9194f7435add7db6fea9b8c29a6d94db5968b80ed8b5493c",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n_z",
                        column: "decimals_n_z_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "decimalsNZId",
                        propertyDescription: "Description for decimalsNZId",
                        propertyRestName: "decimals-n-z-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n_z",
                        column: "decimals_n_z_fixed",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 3,
                        numericScale: 0,
                        propertyName: "decimalsNZFixed",
                        propertyDescription: "Description for decimalsNZFixed",
                        propertyRestName: "decimals-n-z-fixed",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n_z",
                        column: "decimals_n_z_numeric",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 3,
                        numericScale: 0,
                        propertyName: "decimalsNZNumeric",
                        propertyDescription: "Description for decimalsNZNumeric",
                        propertyRestName: "decimals-n-z-numeric",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "decimals_n_z",
                        column: "decimals_n_z_decimal",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 3,
                        numericScale: 0,
                        propertyName: "decimalsNZDecimal",
                        propertyDescription: "Description for decimalsNZDecimal",
                        propertyRestName: "decimals-n-z-decimal",
                    },
                ],
                entityName: "DecimalsNZ",
                entityDescription: "Description for DecimalsNZ",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "foo",
                sign: "8a7b4f917ab0f1d1d8fb3c89ee97f810c3aaf42990d4983289f5e8fc1cbcc59c",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "fooId",
                        propertyDescription: "Description for fooId",
                        propertyRestName: "foo-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_char",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "char",
                        maxLength: 2,
                        propertyName: "fooChar",
                        propertyDescription: "Description for fooChar",
                        propertyRestName: "foo-char",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_varchar",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "varchar",
                        maxLength: 4,
                        propertyName: "fooVarchar",
                        propertyDescription: "Description for fooVarchar",
                        propertyRestName: "foo-varchar",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_text",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "text",
                        maxLength: 65535,
                        propertyName: "fooText",
                        propertyDescription: "Description for fooText",
                        propertyRestName: "foo-text",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_smallint",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "smallint",
                        propertyName: "fooSmallint",
                        propertyDescription: "Description for fooSmallint",
                        propertyRestName: "foo-smallint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_integer",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "int",
                        propertyName: "fooInteger",
                        propertyDescription: "Description for fooInteger",
                        propertyRestName: "foo-integer",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_bigint",
                        ordinal: 7,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "bigint",
                        propertyName: "fooBigint",
                        propertyDescription: "Description for fooBigint",
                        propertyRestName: "foo-bigint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_real",
                        ordinal: 8,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "fooReal",
                        propertyDescription: "Description for fooReal",
                        propertyRestName: "foo-real",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_double",
                        ordinal: 9,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "fooDouble",
                        propertyDescription: "Description for fooDouble",
                        propertyRestName: "foo-double",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_decimal",
                        ordinal: 10,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "decimal",
                        numericPrecision: 6,
                        numericScale: 4,
                        propertyName: "fooDecimal",
                        propertyDescription: "Description for fooDecimal",
                        propertyRestName: "foo-decimal",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_boolean",
                        ordinal: 11,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "tinyint(1)",
                        propertyName: "fooBoolean",
                        propertyDescription: "Description for fooBoolean",
                        propertyRestName: "foo-boolean",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_date",
                        ordinal: 12,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "date",
                        propertyName: "fooDate",
                        propertyDescription: "Description for fooDate",
                        propertyRestName: "foo-date",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "foo",
                        column: "foo_timestamp",
                        ordinal: 13,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "timestamp",
                        timestampPrecision: 3,
                        propertyName: "fooTimestamp",
                        propertyDescription: "Description for fooTimestamp",
                        propertyRestName: "foo-timestamp",
                    },
                ],
                entityName: "Foo",
                entityDescription: "Description for Foo",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "integers",
                sign: "36b2ff8db9e9222351bb05141fa3a9ba7c848cea909efe0b66095759223e3639",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "integers",
                        column: "integers_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "integersId",
                        propertyDescription: "Description for integersId",
                        propertyRestName: "integers-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "integers",
                        column: "integers_integer",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "int",
                        propertyName: "integersInteger",
                        propertyDescription: "Description for integersInteger",
                        propertyRestName: "integers-integer",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "integers",
                        column: "integers_int4",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "int",
                        propertyName: "integersInt4",
                        propertyDescription: "Description for integersInt4",
                        propertyRestName: "integers-int4",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "integers",
                        column: "integers_int",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "int",
                        propertyName: "integersInt",
                        propertyDescription: "Description for integersInt",
                        propertyRestName: "integers-int",
                    },
                ],
                entityName: "Integers",
                entityDescription: "Description for Integers",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "look",
                sign: "713e74876d9dddf45b8b7c715ecf2b688115671b78d248bc03e0dceaa793f051",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_org",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "bigint",
                        propertyName: "lookOrg",
                        propertyDescription: "Description for lookOrg",
                        propertyRestName: "look-org",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_id",
                        ordinal: 2,
                        primaryKey: true,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "bigint",
                        propertyName: "lookId",
                        propertyDescription: "Description for lookId",
                        propertyRestName: "look-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_char",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "char",
                        maxLength: 1,
                        propertyName: "lookChar",
                        propertyDescription: "Description for lookChar",
                        propertyRestName: "look-char",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_varchar",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "varchar",
                        maxLength: 100,
                        propertyName: "lookVarchar",
                        propertyDescription: "Description for lookVarchar",
                        propertyRestName: "look-varchar",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_text",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "text",
                        maxLength: 65535,
                        propertyName: "lookText",
                        propertyDescription: "Description for lookText",
                        propertyRestName: "look-text",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_smallint",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "smallint",
                        propertyName: "lookSmallint",
                        propertyDescription: "Description for lookSmallint",
                        propertyRestName: "look-smallint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_integer",
                        ordinal: 7,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "lookInteger",
                        propertyDescription: "Description for lookInteger",
                        propertyRestName: "look-integer",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_bigint",
                        ordinal: 8,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "bigint",
                        propertyName: "lookBigint",
                        propertyDescription: "Description for lookBigint",
                        propertyRestName: "look-bigint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_real",
                        ordinal: 9,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "double",
                        propertyName: "lookReal",
                        propertyDescription: "Description for lookReal",
                        propertyRestName: "look-real",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_double",
                        ordinal: 10,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "double",
                        propertyName: "lookDouble",
                        propertyDescription: "Description for lookDouble",
                        propertyRestName: "look-double",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_decimal",
                        ordinal: 11,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "decimal",
                        numericPrecision: 10,
                        numericScale: 0,
                        propertyName: "lookDecimal",
                        propertyDescription: "Description for lookDecimal",
                        propertyRestName: "look-decimal",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_boolean",
                        ordinal: 12,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "tinyint(1)",
                        propertyName: "lookBoolean",
                        propertyDescription: "Description for lookBoolean",
                        propertyRestName: "look-boolean",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_date",
                        ordinal: 13,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "date",
                        propertyName: "lookDate",
                        propertyDescription: "Description for lookDate",
                        propertyRestName: "look-date",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "look",
                        column: "look_timestamp",
                        ordinal: 14,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: false,
                        dataType: "timestamp",
                        timestampPrecision: 0,
                        propertyName: "lookTimestamp",
                        propertyDescription: "Description for lookTimestamp",
                        propertyRestName: "look-timestamp",
                    },
                ],
                entityName: "Look",
                entityDescription: "Description for Look",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "mediumints",
                sign: "268faddfff8755de1ae373121d0fb21f9e592d3a377628245c3f7895b377b431",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "mediumints",
                        column: "mediumints_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "mediumint",
                        propertyName: "mediumintsId",
                        propertyDescription: "Description for mediumintsId",
                        propertyRestName: "mediumints-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "mediumints",
                        column: "mediumints_int3",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "mediumint",
                        propertyName: "mediumintsInt3",
                        propertyDescription: "Description for mediumintsInt3",
                        propertyRestName: "mediumints-int3",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "mediumints",
                        column: "mediumints_middleint",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "mediumint",
                        propertyName: "mediumintsMiddleint",
                        propertyDescription: "Description for mediumintsMiddleint",
                        propertyRestName: "mediumints-middleint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "mediumints",
                        column: "mediumints_mediumint",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "mediumint",
                        propertyName: "mediumintsMediumint",
                        propertyDescription: "Description for mediumintsMediumint",
                        propertyRestName: "mediumints-mediumint",
                    },
                ],
                entityName: "Mediumints",
                entityDescription: "Description for Mediumints",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "reals",
                sign: "d82c1e71becc587058020924f2dea5a4f9cf7435018499ae71d6f0c1353135ce",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals",
                        column: "reals_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "realsId",
                        propertyDescription: "Description for realsId",
                        propertyRestName: "reals-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals",
                        column: "reals_float4",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsFloat4",
                        propertyDescription: "Description for realsFloat4",
                        propertyRestName: "reals-float4",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals",
                        column: "reals_float",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsFloat",
                        propertyDescription: "Description for realsFloat",
                        propertyRestName: "reals-float",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals",
                        column: "reals_float8",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsFloat8",
                        propertyDescription: "Description for realsFloat8",
                        propertyRestName: "reals-float8",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals",
                        column: "reals_real",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsReal",
                        propertyDescription: "Description for realsReal",
                        propertyRestName: "reals-real",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals",
                        column: "reals_double_precision",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsDoublePrecision",
                        propertyDescription: "Description for realsDoublePrecision",
                        propertyRestName: "reals-double-precision",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals",
                        column: "reals_double",
                        ordinal: 7,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsDouble",
                        propertyDescription: "Description for realsDouble",
                        propertyRestName: "reals-double",
                    },
                ],
                entityName: "Reals",
                entityDescription: "Description for Reals",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "reals_n",
                sign: "d5f125a5dfaf637f4feb8c252464eb5056b42f29d25d46d30ce3474fb6157488",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "realsNId",
                        propertyDescription: "Description for realsNId",
                        propertyRestName: "reals-n-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_one",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZOne",
                        propertyDescription: "Description for realsNZOne",
                        propertyRestName: "reals-n-z-one",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_two",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZTwo",
                        propertyDescription: "Description for realsNZTwo",
                        propertyRestName: "reals-n-z-two",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_three",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZThree",
                        propertyDescription: "Description for realsNZThree",
                        propertyRestName: "reals-n-z-three",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_four",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZFour",
                        propertyDescription: "Description for realsNZFour",
                        propertyRestName: "reals-n-z-four",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_five",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZFive",
                        propertyDescription: "Description for realsNZFive",
                        propertyRestName: "reals-n-z-five",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_six",
                        ordinal: 7,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZSix",
                        propertyDescription: "Description for realsNZSix",
                        propertyRestName: "reals-n-z-six",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_seven",
                        ordinal: 8,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZSeven",
                        propertyDescription: "Description for realsNZSeven",
                        propertyRestName: "reals-n-z-seven",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_eight",
                        ordinal: 9,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZEight",
                        propertyDescription: "Description for realsNZEight",
                        propertyRestName: "reals-n-z-eight",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_nine",
                        ordinal: 10,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZNine",
                        propertyDescription: "Description for realsNZNine",
                        propertyRestName: "reals-n-z-nine",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_ten",
                        ordinal: 11,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZTen",
                        propertyDescription: "Description for realsNZTen",
                        propertyRestName: "reals-n-z-ten",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_eleven",
                        ordinal: 12,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZEleven",
                        propertyDescription: "Description for realsNZEleven",
                        propertyRestName: "reals-n-z-eleven",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_twelve",
                        ordinal: 13,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZTwelve",
                        propertyDescription: "Description for realsNZTwelve",
                        propertyRestName: "reals-n-z-twelve",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_thriteen",
                        ordinal: 14,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZThriteen",
                        propertyDescription: "Description for realsNZThriteen",
                        propertyRestName: "reals-n-z-thriteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_fourteen",
                        ordinal: 15,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZFourteen",
                        propertyDescription: "Description for realsNZFourteen",
                        propertyRestName: "reals-n-z-fourteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_fiveteen",
                        ordinal: 16,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZFiveteen",
                        propertyDescription: "Description for realsNZFiveteen",
                        propertyRestName: "reals-n-z-fiveteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_sixteen",
                        ordinal: 17,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZSixteen",
                        propertyDescription: "Description for realsNZSixteen",
                        propertyRestName: "reals-n-z-sixteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_seventeen",
                        ordinal: 18,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZSeventeen",
                        propertyDescription: "Description for realsNZSeventeen",
                        propertyRestName: "reals-n-z-seventeen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_eighteen",
                        ordinal: 19,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZEighteen",
                        propertyDescription: "Description for realsNZEighteen",
                        propertyRestName: "reals-n-z-eighteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_nineteen",
                        ordinal: 20,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZNineteen",
                        propertyDescription: "Description for realsNZNineteen",
                        propertyRestName: "reals-n-z-nineteen",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_twenty",
                        ordinal: 21,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZTwenty",
                        propertyDescription: "Description for realsNZTwenty",
                        propertyRestName: "reals-n-z-twenty",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_twentyone",
                        ordinal: 22,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZTwentyone",
                        propertyDescription: "Description for realsNZTwentyone",
                        propertyRestName: "reals-n-z-twentyone",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n",
                        column: "reals_n_z_twentytwo",
                        ordinal: 23,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZTwentytwo",
                        propertyDescription: "Description for realsNZTwentytwo",
                        propertyRestName: "reals-n-z-twentytwo",
                    },
                ],
                entityName: "RealsN",
                entityDescription: "Description for RealsN",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "reals_n_m",
                sign: "4a2a12090813ef7b08f9d2e1e400b3bf8e7064207d37cf2cdebbfbae9ab6935a",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_m",
                        column: "reals_n_m_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "realsNMId",
                        propertyDescription: "Description for realsNMId",
                        propertyRestName: "reals-n-m-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_m",
                        column: "reals_n_z_float4",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZFloat4",
                        propertyDescription: "Description for realsNZFloat4",
                        propertyRestName: "reals-n-z-float4",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_m",
                        column: "reals_n_z_float",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZFloat",
                        propertyDescription: "Description for realsNZFloat",
                        propertyRestName: "reals-n-z-float",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_m",
                        column: "reals_n_z_float8",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZFloat8",
                        propertyDescription: "Description for realsNZFloat8",
                        propertyRestName: "reals-n-z-float8",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_m",
                        column: "reals_n_z_real",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZReal",
                        propertyDescription: "Description for realsNZReal",
                        propertyRestName: "reals-n-z-real",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_m",
                        column: "reals_n_z_double_precision",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZDoublePrecision",
                        propertyDescription: "Description for realsNZDoublePrecision",
                        propertyRestName: "reals-n-z-double-precision",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_m",
                        column: "reals_n_z_double",
                        ordinal: 7,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZDouble",
                        propertyDescription: "Description for realsNZDouble",
                        propertyRestName: "reals-n-z-double",
                    },
                ],
                entityName: "RealsNM",
                entityDescription: "Description for RealsNM",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "reals_n_z",
                sign: "3ed0e41ebf4f111b4deb60f8afeaf0dfd643a708b0a771f5908aa481963f4e05",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_z",
                        column: "reals_n_z_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "int",
                        propertyName: "realsNZId",
                        propertyDescription: "Description for realsNZId",
                        propertyRestName: "reals-n-z-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_z",
                        column: "reals_n_z_float4",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZFloat4",
                        propertyDescription: "Description for realsNZFloat4",
                        propertyRestName: "reals-n-z-float4",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_z",
                        column: "reals_n_z_float",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "float",
                        propertyName: "realsNZFloat",
                        propertyDescription: "Description for realsNZFloat",
                        propertyRestName: "reals-n-z-float",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_z",
                        column: "reals_n_z_float8",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZFloat8",
                        propertyDescription: "Description for realsNZFloat8",
                        propertyRestName: "reals-n-z-float8",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_z",
                        column: "reals_n_z_real",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZReal",
                        propertyDescription: "Description for realsNZReal",
                        propertyRestName: "reals-n-z-real",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_z",
                        column: "reals_n_z_double_precision",
                        ordinal: 6,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZDoublePrecision",
                        propertyDescription: "Description for realsNZDoublePrecision",
                        propertyRestName: "reals-n-z-double-precision",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "reals_n_z",
                        column: "reals_n_z_double",
                        ordinal: 7,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "double",
                        propertyName: "realsNZDouble",
                        propertyDescription: "Description for realsNZDouble",
                        propertyRestName: "reals-n-z-double",
                    },
                ],
                entityName: "RealsNZ",
                entityDescription: "Description for RealsNZ",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "smallints",
                sign: "f4b1ea20e433dc4be2367fb79861e9d4d6d66bb93b82ba585b9a52769ac0f87c",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "smallints",
                        column: "smallints_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "smallint",
                        propertyName: "smallintsId",
                        propertyDescription: "Description for smallintsId",
                        propertyRestName: "smallints-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "smallints",
                        column: "smallints_int2",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "smallint",
                        propertyName: "smallintsInt2",
                        propertyDescription: "Description for smallintsInt2",
                        propertyRestName: "smallints-int2",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "smallints",
                        column: "smallints_smallint",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "smallint",
                        propertyName: "smallintsSmallint",
                        propertyDescription: "Description for smallintsSmallint",
                        propertyRestName: "smallints-smallint",
                    },
                ],
                entityName: "Smallints",
                entityDescription: "Description for Smallints",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "tinyints",
                sign: "25411bfeb830be76991ec43307a3df1098d490b5220c71eba6985d70b6e3106c",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "tinyints",
                        column: "tinyints_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "tinyint",
                        propertyName: "tinyintsId",
                        propertyDescription: "Description for tinyintsId",
                        propertyRestName: "tinyints-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "tinyints",
                        column: "tinyints_int1",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "tinyint",
                        propertyName: "tinyintsInt1",
                        propertyDescription: "Description for tinyintsInt1",
                        propertyRestName: "tinyints-int1",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "tinyints",
                        column: "tinyints_tinyint",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "tinyint",
                        propertyName: "tinyintsTinyint",
                        propertyDescription: "Description for tinyintsTinyint",
                        propertyRestName: "tinyints-tinyint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "tinyints",
                        column: "tinyints_bool",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "tinyint(1)",
                        propertyName: "tinyintsBool",
                        propertyDescription: "Description for tinyintsBool",
                        propertyRestName: "tinyints-bool",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "tinyints",
                        column: "tinyints_boolean",
                        ordinal: 5,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "tinyint(1)",
                        propertyName: "tinyintsBoolean",
                        propertyDescription: "Description for tinyintsBoolean",
                        propertyRestName: "tinyints-boolean",
                    },
                ],
                entityName: "Tinyints",
                entityDescription: "Description for Tinyints",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "ubigints",
                sign: "10abb0d7581cce4c7e568543590ccfd7a52bd6aa75f1dafb8c1f3ea80859c0f3",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "ubigints",
                        column: "ubigints_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "bigintunsigned",
                        propertyName: "ubigintsId",
                        propertyDescription: "Description for ubigintsId",
                        propertyRestName: "ubigints-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "ubigints",
                        column: "ubigints_int8",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "bigintunsigned",
                        propertyName: "ubigintsInt8",
                        propertyDescription: "Description for ubigintsInt8",
                        propertyRestName: "ubigints-int8",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "ubigints",
                        column: "ubigints_bigint",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "bigintunsigned",
                        propertyName: "ubigintsBigint",
                        propertyDescription: "Description for ubigintsBigint",
                        propertyRestName: "ubigints-bigint",
                    },
                ],
                entityName: "Ubigints",
                entityDescription: "Description for Ubigints",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "uintegers",
                sign: "aa81c792d7f88ec6694eed2b05a029ce477067e99ed1ac7e8a230472c56df230",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "uintegers",
                        column: "uintegers_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "intunsigned",
                        propertyName: "uintegersId",
                        propertyDescription: "Description for uintegersId",
                        propertyRestName: "uintegers-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "uintegers",
                        column: "uintegers_integer",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "intunsigned",
                        propertyName: "uintegersInteger",
                        propertyDescription: "Description for uintegersInteger",
                        propertyRestName: "uintegers-integer",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "uintegers",
                        column: "uintegers_int4",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "intunsigned",
                        propertyName: "uintegersInt4",
                        propertyDescription: "Description for uintegersInt4",
                        propertyRestName: "uintegers-int4",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "uintegers",
                        column: "uintegers_int",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "intunsigned",
                        propertyName: "uintegersInt",
                        propertyDescription: "Description for uintegersInt",
                        propertyRestName: "uintegers-int",
                    },
                ],
                entityName: "Uintegers",
                entityDescription: "Description for Uintegers",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "umediumints",
                sign: "ef914f9f3d12bcd26f6e7b9c609303f02a63227c58c1fc1837809c42b28a621c",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "umediumints",
                        column: "umediumints_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "mediumintunsigned",
                        propertyName: "umediumintsId",
                        propertyDescription: "Description for umediumintsId",
                        propertyRestName: "umediumints-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "umediumints",
                        column: "umediumints_int3",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "mediumintunsigned",
                        propertyName: "umediumintsInt3",
                        propertyDescription: "Description for umediumintsInt3",
                        propertyRestName: "umediumints-int3",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "umediumints",
                        column: "umediumints_middleint",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "mediumintunsigned",
                        propertyName: "umediumintsMiddleint",
                        propertyDescription: "Description for umediumintsMiddleint",
                        propertyRestName: "umediumints-middleint",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "umediumints",
                        column: "umediumints_mediumint",
                        ordinal: 4,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "mediumintunsigned",
                        propertyName: "umediumintsMediumint",
                        propertyDescription: "Description for umediumintsMediumint",
                        propertyRestName: "umediumints-mediumint",
                    },
                ],
                entityName: "Umediumints",
                entityDescription: "Description for Umediumints",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "usmallints",
                sign: "c7339a2962ccb2bc581f6f6043de47187506947166dd5b79bd9dcc4de4b19328",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "usmallints",
                        column: "usmallints_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "smallintunsigned",
                        propertyName: "usmallintsId",
                        propertyDescription: "Description for usmallintsId",
                        propertyRestName: "usmallints-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "usmallints",
                        column: "usmallints_int2",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "smallintunsigned",
                        propertyName: "usmallintsInt2",
                        propertyDescription: "Description for usmallintsInt2",
                        propertyRestName: "usmallints-int2",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "usmallints",
                        column: "usmallints_smallint",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "smallintunsigned",
                        propertyName: "usmallintsSmallint",
                        propertyDescription: "Description for usmallintsSmallint",
                        propertyRestName: "usmallints-smallint",
                    },
                ],
                entityName: "Usmallints",
                entityDescription: "Description for Usmallints",
            },
            {
                catalog: "crud_base_db",
                schema: "crud_base_db",
                table: "utinyints",
                sign: "6ea18c0ac8ebb995fbd262681a3bb3864f8b6249e7710bb0948e9e64228521c7",
                columns: [
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "utinyints",
                        column: "utinyints_id",
                        ordinal: 1,
                        primaryKey: true,
                        primaryKeyAutoGenerated: true,
                        foreingKey: false,
                        nullable: false,
                        dataType: "tinyintunsigned",
                        propertyName: "utinyintsId",
                        propertyDescription: "Description for utinyintsId",
                        propertyRestName: "utinyints-id",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "utinyints",
                        column: "utinyints_int1",
                        ordinal: 2,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "tinyintunsigned",
                        propertyName: "utinyintsInt1",
                        propertyDescription: "Description for utinyintsInt1",
                        propertyRestName: "utinyints-int1",
                    },
                    {
                        catalog: "crud_base_db",
                        schema: "crud_base_db",
                        table: "utinyints",
                        column: "utinyints_tinyint",
                        ordinal: 3,
                        primaryKey: false,
                        primaryKeyAutoGenerated: false,
                        foreingKey: false,
                        nullable: true,
                        dataType: "tinyintunsigned",
                        propertyName: "utinyintsTinyint",
                        propertyDescription: "Description for utinyintsTinyint",
                        propertyRestName: "utinyints-tinyint",
                    },
                ],
                entityName: "Utinyints",
                entityDescription: "Description for Utinyints",
            },
        ],
    },
};

export default data;