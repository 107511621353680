<script>
  import ArrowUp from "../svg/ArrowUp.svelte";
  let resultData = JSON.parse(localStorage.getItem("crudResponse"));
</script>

<div class="cont-top-data thanks">
  <div class="custom-scrollbar-css pr-2 text-center">
    <h1>
      THANKS YOU <span>for your download!</span>
    </h1>
    <p>
      If the download doesn't start in a few seconds, please
      <a href={resultData.downloadUri}>click here</a> to start the download.
    </p>
    <br />
    <h2>{resultData.totalBuilds} Project completed</h2>
    <div class="accordion" id="accordionExample">
      <div class="card pb-4">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button
              class="btn btn-link btn-block text-left collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne">
              You have a savings of US$ {resultData.savings.total} using Mamasara
              <span>review <ArrowUp /></span>
            </button>
          </h2>
        </div>
        <div
          class="collapse"
          id="collapseOne"
          aria-labelledby="headingOne"
          data-parent="#accordionExample">
          <div class="card-body pb-0">
            <div class="row">
              {#each resultData.savings.details as detail}
                <div class="col-xs-12 col-md-6 col-lg-3">
                  <p>
                    {detail.concept} <b>${detail.amount}</b>
                  </p>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3>HELP US TO KEEP GROWING</h3>
    <p>
      If you value Eclipse technologies, please consider making a donation.
      Contributions from users like you help fund the operations of the Eclipse
      Foundation. All money donated to the Eclipse Foundation will be used to
      support the Eclipse Community.
    </p>
    <input
      id="ex21"
      type="text"
      data-provide="slider"
      data-slider-ticks="[100, 300, 500, 700, 900]"
      data-slider-ticks-labels="[&quot;$100&quot;, &quot;$300&quot;, &quot;$500&quot;, &quot;$700&quot;, &quot;$900&quot;]"
      data-slider-min="100"
      data-slider-max="900"
      data-slider-step="200"
      data-slider-value="100"
      data-slider-tooltip="hide"
      onchange="updateTextInput(this.value);" />
    <form>
      <div class="input-group col-lg-4 mx-auto pt-5 pb-2">
        <input
          class="form-control"
          id="valueSlide"
          type="number"
          placeholder="$100"
          aria-label="$100"
          aria-describedby="basic-addon2" />
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2">USD</span>
        </div>
        <button
          class="ml-4 button-green"
          id="button-addon2"
          type="button"
          data-toggle="modal"
          data-target="#exampleModal">
          <span>Donar</span></button>
      </div>
    </form>
    <div class="d-flex justify-content-center center-block p-4">
      <blockquote class="col-12 col-sm-3" />
    </div>
    <div class="alert alert-primary text-center m-0 p-4">
      <span class="align-middle">SPAM</span>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Thank you for supporting our community!
        </h5>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="input-group col-6 mx-auto">
          <input
            class="form-control"
            id="valueSlide_copy"
            type="number"
            placeholder="$100"
            aria-label="$100"
            aria-describedby="basic-addon2" />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">USD</span>
          </div>
        </div>
        <div class="row justify-content-center my-4">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              id="langropt1"
              type="radio"
              name="langropt"
              value="One-time" />
            <label class="form-check-label" for="langropt1">One-time </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              id="langropt2"
              type="radio"
              name="langropt"
              value="Monthly" />
            <label class="form-check-label" for="langropt2">Monthly</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              id="langropt3"
              type="radio"
              name="langropt"
              value="Yearly" />
            <label class="form-check-label" for="langropt3">Yearly</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="button-green" type="button"> <span>Donate</span></button>
      </div>
    </div>
  </div>
</div>
