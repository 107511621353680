<script>
  import TableCard from "./components/TableCard.svelte";
  import { setContext } from "svelte";

  import data from "../scripts/schema-mock";

  var table;
  var column;
  function pickColumn(tableIndex, columnIndex) {
    table = data.schema.tables[tableIndex];
    column = table.columns[columnIndex];
  }
  setContext("pickColumn", pickColumn);
  pickColumn(0, 0);

  $: restUri =
    "/api/v1/" +
    table.entityName.charAt(0).toLowerCase() +
    table.entityName.slice(1) +
    "/{" +
    table.entityName.charAt(0).toLowerCase() +
    table.entityName.slice(1) +
    "}";
</script>

<div class="cont-top-data">
  <div class="custom-scrollbar-css pr-2">
    <form class="row">
      <div class="col-xl-4 col-lg-12 align-self-top">
        <div class="card custom-scrollbar-css">
          <div class="card-header">
            <h2 class="mb-0 border-0">
              <button class="btn btn-link btn-block text-left"
                ><span>Custom properties</span></button>
            </h2>
          </div>
          <div class="accordion green" id="accordionExample">
            {#each data.schema.tables as table, tableIndex}
              <TableCard {...table} {tableIndex} />
            {/each}
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-12 align-self-top border-left">
        <div class="card">
          <div class="card-body">
            <h3>{table.table}</h3>
            <div class="row from-in">
              <div class="col">
                <label
                  for="entity-name"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Name of the entity that this table represents"
                  >Entity name (?) :</label>
                <input
                  id="entity-name"
                  class="form-control"
                  type="text"
                  placeholder="Entity name represented by {table.table} table"
                  bind:value={table.entityName} />
              </div>
            </div>
            <div class="row from-in">
              <div class="col">
                <label
                  for="entity-description"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Description for the entity, this will be used in Javadoc and Spring REST Docs"
                  >Entity description (?) :</label>
                <input
                  id="entity-description"
                  class="form-control"
                  type="text"
                  placeholder={table.entityDescription}
                  bind:value={table.entityDescription} />
              </div>
            </div>
            <div class="condition">
              <!-- <div class="row from-in">
                <div class="col">
                  <label>Use URI Rest</label>
                </div>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  id="urirest1"
                  type="radio"
                  name="urirest"
                  value="Enable" />
                <label class="form-check-label" for="urirest1">Enable</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  id="urirest2"
                  type="radio"
                  name="urirest"
                  value="Disable" />
                <label class="form-check-label" for="urirest2">Disable</label>
              </div> -->
              <div class="row from-in view">
                <div class="col">
                  <label
                    for="rest-uri"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Tooltip on top">URI Rest (?) :</label>
                  <input
                    id="rest-uri"
                    class="form-control"
                    type="text"
                    value={restUri}
                    readonly />
                </div>
              </div>
            </div>
            <h3>{column.column}</h3>
            <div class="row from-in">
              <div class="col">
                <label
                  for="property-name"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Name of the property that this column represents"
                  >Property name (?) :</label>
                <input
                  id="property-name"
                  class="form-control"
                  type="text"
                  placeholder={column.propertyName}
                  bind:value={column.propertyName} />
              </div>
            </div>
            <div class="row from-in">
              <div class="col">
                <label
                  for="property-description"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Description for the property, this will be used in Javadoc."
                  >Property description (?) :</label>
                <input
                  id="property-description"
                  class="form-control"
                  type="text"
                  placeholder={column.propertyDescription}
                  bind:value={column.propertyDescription} />
              </div>
            </div>
            <div class="condition">
              <!-- <div class="row from-in">
                <div class="col">
                  <label>Use Data type</label>
                </div>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  id="dattype1"
                  type="radio"
                  name="dattype"
                  value="Enable" />
                <label class="form-check-label" for="dattype1">Enable</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  id="dattype2"
                  type="radio"
                  name="dattype"
                  value="Disable" />
                <label class="form-check-label" for="dattype2">Disable</label>
              </div> -->
              <div class="row from-in view">
                <div class="col">
                  <label
                    for="property-rest-name"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Name that the property will have when exposed through the rest interface, this will be used in Spring REST Docs"
                    >Rest property name (?) :</label>
                  <input
                    id="property-rest-name"
                    class="form-control"
                    type="text"
                    placeholder={column.propertyRestName}
                    bind:value={column.propertyRestName} />
                </div>
              </div>
            </div>
            <div class="row from-in">
              <div class="col">
                <label for=""> Data Type: <b>{column.dataType}</b></label>
              </div>
            </div>
            <div class="row from-in">
              <div class="col">
                <label for=""> Primary Key: <b>{column.primaryKey}</b></label>
              </div>
            </div>
            <div class="row from-in">
              <div class="col">
                <label for=""> Foreing Key: <b>{column.foreingKey}</b></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
