import axios from 'axios'

const wrapApp = {
	custom_bar: function () {
		$("#content-scroll-one").mCustomScrollbar({});
	},

	menu: function () {
		$('button.menu').click(function () {
			$('.modal-menu.theme-app').addClass('open-menu');
			$('button.menu').addClass('opened').attr('aria-expanded', 'true');
		});
		$('button.close').click(function () {
			$('.modal-menu.theme-app').removeClass('open-menu');
			$('button.menu').removeClass('opened').removeAttr('aria-expanded');
		});
	},

	bootstrap_active: function () {
		$('[data-toggle="tooltip"]').tooltip();

		$('input[type="file"]').change(function (e) {
			var fileName = e.target.files[0].name;
			$('.custom-file-label').html(fileName);
		});
	},

	validate_condition: function () {
		$('input[type="radio"]').click(function () {
			var inputValue = $(this).attr("value");
			var view = $(this).parent().parent().find('.view');
			if (inputValue == "Enable") {
				view.removeClass('d-none');
			} else {
				view.addClass('d-none');
			}
		});
	},

	addons: function () {
		$('.cont-change-theme .change .light').click(function () {
			$('body').removeClass('dark');
			$('.cont-change-theme .change .light').parent().removeClass('active');
		});
		$('.cont-change-theme .change .dark').click(function () {
			$('body').addClass('dark');
			$('.cont-change-theme .change .dark').parent().addClass('active');
		});

		$('.theme-app .cont-top-data .accordion.green .card .card-body ul > li').click(function () {
			$('.theme-app .cont-top-data .accordion.green .card .card-body ul').find('li.active').removeClass('active');
			$(this).toggleClass('active');
		});
		$('.theme-app .cont-top-data .accordion.green .card .card-body ul li ul li a').click(function () {
			$('.theme-app .cont-top-data .accordion.green .card .card-body ul li ul').find('.active').removeClass('active');
			$(this).addClass('active');
		});
	},

	search: function () {
		$("#search").on("input", function (e) {
			let input = $(this).val()
			let filter = input.toUpperCase()
			$(".cont-addons ul li").each(function () {
				let li = $(this);
				if (li.data("meta").toUpperCase().indexOf(filter) > -1) {
					li.removeClass("d-none").addClass("active");
				} else {
					li.addClass("d-none").removeClass("active");
				}
			});
			if (input == "") {
				$(".cont-addons ul li").removeClass("active");
			}

		});
	},
	validateStepOne: function () {
		var form = document.forms["formStepOne"];
		if (form.checkValidity()) {
			let url = "http://localhost:8080/api/v1/schemas";
			let params = $('#formStepOne').serializeJSON().jdbcSetting;
			axios.request({
				url: url,
				method: "GET",
				params: params
			}).then((response) => {
				localStorage.setItem("schemas", JSON.stringify(response.data));
				window.location.href = "/builder/config";
			}).catch((error) => {
				if (!error.response) {
					$("#messageModalLabel").html("Error");
					$("#messageModalBody").html("An error has occurred connecting to our servers, please try again in a few minutes");
				} else {
					$("#messageModalLabel").html(error.response.data.error);
					$("#messageModalBody").html(error.response.data.message);
				}
				$("#messageModal").modal("show");
			});
		} else {
			form.classList.add("was-validated");
			let invalidField = $(form.querySelectorAll(':invalid')).first();
			invalidField.parents(".collapse").collapse('show');
			invalidField.focus();
		}
	}
}
export default wrapApp;