<script>
  import faqData from "../scripts/faq-data";

  import FaqAnswer from "../components/FaqAnswer.svelte";
  import FaqQuestion from "../components/FaqQuestion.svelte";
  import InfoFooter from "./../components/InfoFooter.svelte";
  import CookiePolicyModal from "./../components/CookiePolicyModal.svelte";
  import { onMount } from "svelte";
  import wrapApp from "./../scripts/mamasara";

  let cssClass;

  onMount(async () => {
    wrapApp.addons();
    wrapApp.search();
    wrapApp.menu();
    wrapApp.bootstrap_active();
    wrapApp.validate_condition();
    wrapApp.custom_bar();
  });
</script>

<div class="container-fluid theme-landing">
  <section class="container cont-default">
    <div class="row">
      <div class="col-12 mb-3">
        <a class="button" href="javascript:window.history.back()" title="Back"
          ><span>Back</span></a>
      </div>
    </div>
    <div class="row cont-faq" id="accordion">
      <div class="col col-md-4 text-right">
        <ul>
          {#each faqData as data, index}
            <FaqQuestion {data} {index} />
          {/each}
        </ul>
      </div>
      <div class="col">
        {#each faqData as data, index}
          <FaqAnswer {data} {index} />
        {/each}
      </div>
    </div>
  </section>
  <InfoFooter />
</div>
<CookiePolicyModal />
