<script>
  import { v4 as uuid } from "uuid";
  import UpDownArrow from "../../svg/UpDownArrow.svelte";
  import { getContext } from "svelte";

  export let table;
  export let columns;
  export let tableIndex;

  let id = uuid();
  let buttonClass;
  let buttonExpanded;
  let divClass;

  let pickColumn = getContext("pickColumn");

  if (tableIndex === 0) {
    buttonClass = "btn btn-link btn-block text-left";
    buttonExpanded = "true";
    divClass = "collapse show";
  } else {
    buttonClass = "btn btn-link btn-block text-left collapsed";
    buttonExpanded = "false";
    divClass = "collapse";
  }
</script>

<div class="card">
  <div class="card-header" id="heading-{id}">
    <h2 class="mb-0">
      <button
        class={buttonClass}
        type="button"
        data-toggle="collapse"
        data-target="#collapse-{id}"
        aria-expanded={buttonExpanded}
        aria-controls="collapse-{id}"
        ><span>{table}</span>
        <UpDownArrow />
      </button>
    </h2>
  </div>
  <div
    class={divClass}
    id="collapse-{id}"
    aria-labelledby="heading-{id}"
    data-parent="#accordionExample">
    <div class="card-body">
      <ul>
        {#each columns as column, columnIndex}
          {#if tableIndex === 0 && columnIndex === 0}
            <li class="active">
              <a
                href="#{table}.{column.column}"
                on:click={pickColumn(tableIndex, columnIndex)}
                title={column.column}>{column.column}</a>
            </li>
          {:else}
            <li>
              <a
                href="#{table}.{column.column}"
                on:click={pickColumn(tableIndex, columnIndex)}
                title={column.column}>{column.column}</a>
            </li>
          {/if}
        {/each}
      </ul>
    </div>
  </div>
</div>
