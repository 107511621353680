<script>
  import { v4 as uuid } from "uuid";

  export let name;
  export let value;
  export let label;
  export let description;
  export let checked = false;

  let id = uuid();
</script>

<li class="p-0 form-check" data-meta={label}>
  {#if checked == true}
    <input class="form-check-input" {id} type="radio" {name} {value} checked />
  {:else}
    <input class="form-check-input" {id} type="radio" {name} {value} />
  {/if}
  <label class="form-check-label" for={id}>{label}</label>
  <p>{description}</p>
</li>
