<script>
  export let data;
  export let index;
</script>

{#if index === 0}
  <div class="collapse show" id={data.anchor} data-parent="#accordion">
    <div class="card card-body">
      <p>{data.answer}</p>
    </div>
  </div>
{:else}
  <div class="collapse" id={data.anchor} data-parent="#accordion">
    <div class="card card-body">
      <p>{data.answer}</p>
    </div>
  </div>
{/if}
