const wrapApp = {
	addons_ready: function(){
	},
	addons_load: function(){
		var typed = new Typed('#typed', {
			stringsElement: '#typed-strings',
			typeSpeed: 40,
			backSpeed: 40,
			startDelay: 2000,
			loop: true
		});
		$('.counter').countUp();
	},

	bootstrap: function(){
		$('[data-toggle="tooltip"]').tooltip();
	},
		
	slide: function(){
		$('.owl-comments').owlCarousel({
			autoplay: false,
			loop: true,
			margin: 0,
			nav: false,
			responsive : {
				// breakpoint from 0 up
				0 : {
					items: 1
				},
				// breakpoint from 480 up
				480 : {
					items: 2
				},
				// breakpoint from 768 up
				768 : {
					items: 3
				}
			}
		});
	},
}
export default wrapApp;