<script>
  import FaqQuestion from "../components/FaqQuestion.svelte";
  import FaqAnswer from "../components/FaqAnswer.svelte";
  import InfoFooter from "../components/InfoFooter.svelte";
  import CookiePolicyModal from "./../components/CookiePolicyModal.svelte";

  import wrapApp from "../scripts/landing";
  import landingData from "../scripts/landing-data";
  import faqData from "../scripts/faq-data";

  import { onMount } from "svelte";

  const faqSize = 3;

  onMount(async () => {
    wrapApp.bootstrap();
    wrapApp.slide();
    wrapApp.addons_ready();
    wrapApp.addons_load();
  });
</script>

<div class="container-fluid theme-landing">
  <section class="row d-flex align-items-center cont-banner">
    <div class="cont-background">
      <img alt="" src="/image/background-landing.webp" />
    </div>
    <div class="col text-center">
      <div class="cont-logo">
        <img src="/image/icon-mamasara-white.webp" alt="Mamasara" />
        <h1 class="type-wrap">
          {landingData.marquee.main}
          <div id="typed-strings">
            {#each landingData.marquee.roulette as roulette}
              <p>{@html roulette}</p>
            {/each}
          </div>
          <span id="typed" />
        </h1>
        <a href="/setup" title="Start"><span>Start</span></a>
      </div>
    </div>
    <div class="cont-animation"><span class="scrolling" /></div>
  </section>
  <section class="container cont-how-use">
    <div class="row">
      <div class="col text-center">
        <h1>How Use / What Is</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 col-sm-3 text-center">
        <blockquote
          data-toggle="tooltip"
          data-placement="bottom"
          title="Configure your project and your data source.">
          <img src="/image/icon-1.png" alt="Setup" />
          <p>Setup</p>
        </blockquote>
      </div>
      <div class="d-none d-sm-block col-1">
        <div class="h-100 d-flex align-items-center text-center arrow">
          <img alt="" src="/image/icon-arrow.png" />
        </div>
      </div>
      <div class="col-4 col-sm-3 text-center">
        <blockquote
          data-toggle="tooltip"
          data-placement="bottom"
          title="Name your tables entities and name your columns properties.">
          <img src="/image/icon-2.png" alt="Name" />
          <p>Name</p>
        </blockquote>
      </div>
      <div class="d-none d-sm-block col-1">
        <div class="h-100 d-flex align-items-center text-center arrow">
          <img alt="" src="/image/icon-arrow.png" />
        </div>
      </div>
      <div class="col-4 col-sm-3 text-center">
        <blockquote
          data-toggle="tooltip"
          data-placement="bottom"
          title="Download your source code and develop your business.">
          <img src="/image/icon-3.png" alt="Download" />
          <p>Download</p>
        </blockquote>
      </div>
    </div>
    <div class="row">
      {#each landingData.valuePropositions as proposition}
        <div class="col-12 col-md-4 text-center">
          <div class="card">
            <p><b>{proposition.title}</b></p>
            <p>{@html proposition.detail}</p>
          </div>
        </div>
      {/each}
    </div>
  </section>
  <section class="cont-cout-create">
    <div class="row">
      <div class="col-6">
        <blockquote>
          <p>
            <span>{landingData.buildSummary.generatedTitle}</span><i
              class="counter">{landingData.buildSummary.generatedAmount}</i>
          </p>
        </blockquote>
      </div>
      <div class="col-6">
        <blockquote>
          <p>
            US$ <i class="counter">{landingData.buildSummary.savingsAmount}</i>
            <span>{landingData.buildSummary.savingsTitle}</span>
          </p>
        </blockquote>
      </div>
    </div>
  </section>
  <section class="container cont-faq">
    <div class="row">
      <div class="col text-center">
        <h1>FAQ</h1>
      </div>
    </div>
    <div class="row" id="accordion">
      <div class="col col-md-4 text-right">
        <ul>
          {#each faqData.slice(0, faqSize) as data, index}
            <FaqQuestion {data} {index} />
          {/each}
        </ul>
      </div>
      <div class="col">
        {#each faqData.slice(0, faqSize) as data, index}
          <FaqAnswer {data} {index} />
        {/each}
      </div>
    </div>
    <div class="row">
      <div class="col text-center cont-button">
        <a href="/faq" title="FAQ"><span>View all Faq</span></a>
      </div>
    </div>
  </section>
  <section class="row cont-founder">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 text-center text-md-left">
          <h1>Lorem ipsum dolor sit amet</h1>
          <p>
            Curabitur convallis porta urna, ut consectetur tellus convallis
            sagittis. In consequat ante lorem, et tempor dui gravida non. Morbi
            nec tristique tortor, accumsan tristique ligula. Nullam convallis
            nibh justo, id consequat justo dictum ut.
          </p>
          <p>
            Duis magna ipsum, tempor vel elit ut, maximus pharetra nulla. Nulla
            consectetur posuere massa dignissim blandit. Sed egestas aliquam
            mattis. Fusce ut pretium nunc. Suspendisse imperdiet
          </p>
        </div>
        <div class="col-12 col-md-4 text-center text-md-right">
          <div class="cont-image">
            <img alt="" src="/image/founder.webp" />
          </div>
          <h2>
            César Bardález Vela<span>CEO FOUNDER</span>
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h3>Technology</h3>
        </div>
      </div>
      <div class="row cont-circle">
        <div class="col">
          <div class="circle" />
          <p>Lorem Ipsum</p>
        </div>
        <div class="col">
          <div class="circle" />
          <p>Lorem Ipsum</p>
        </div>
        <div class="col">
          <div class="circle" />
          <p>Lorem Ipsum</p>
        </div>
        <div class="col">
          <div class="circle" />
          <p>Lorem Ipsum</p>
        </div>
        <div class="col">
          <div class="circle" />
          <p>Lorem Ipsum</p>
        </div>
        <div class="col">
          <div class="circle" />
          <p>Lorem Ipsum</p>
        </div>
      </div>
    </div>
  </section>
  <section class="cont-community">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1>Community</h1>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="owl-carousel owl-theme owl-comments">
            <div class="item">
              <p>
                <strong>James V.</strong>Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.
              </p>
            </div>
            <div class="item">
              <p>
                <strong>Pilar G.</strong>Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.
              </p>
            </div>
            <div class="item">
              <p>
                <strong>Cesar B.</strong>Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.
              </p>
            </div>
            <div class="item">
              <p>
                <strong>Candy V.</strong>Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <InfoFooter />
</div>
<CookiePolicyModal />
