<script>
  import { v4 as uuid } from "uuid";

  export let name;
  export let value;
  export let label;
  export let checked = false;

  let id = uuid();
</script>

<div class="form-check form-check-inline">
  {#if checked == true}
    <input class="form-check-input" {id} type="radio" {name} {value} checked />
  {:else}
    <input class="form-check-input" {id} type="radio" {name} {value} />
  {/if}
  <label class="form-check-label" for={id}>{label}</label>
</div>
