const landingData = {
    marquee: {
        main: "Creas aplicaciones?",
        roulette: [
            "Ahorra tiempo",
            "Preocupese en su negocio no en la tecnologia",
            "Ahorra dinero... es gratis!",
            "Es facil y rapido",
            "Comienza aqui",
        ]
    },
    valuePropositions: [
        {
            title: "Código fuente del microservicio",
            detail: "Se entrega el código fuente modificable con una licencia MIT. El cual permite su uso en ambientes productivos."
        },
        {
            title: "Microservicio Rest",
            detail: "A partir de la estructura de datos en una base de datos PostgreSql o MySql se genera una aplicación CRUD con interface Rest."
        },
        {
            title: "Pruebas sobre el código",
            detail: "Será posible pedir la implementación de pruebas de integración."
        },
        {
            title: "Reduce costos del desarrollo",
            detail: "Al generarse código fuente automatizado y pruebas automatizadas para ese código, se reduce los tiempos de implementación y pruebas lo que representa un menor costo en el desarrollo de microservicios."
        },
        {
            title: "Marco de trabajo a elección",
            detail: "1. Será posible elegir la versión de spring.<br />2. Será posible elegir la tecnologia para persistencia.<br />3. Será posible elejir la tecnologia de pruebas."
        },
        {
            title: "Desarrollo con buenas practicas",
            detail: "1. Arquitectura hexagonal.<br />2. Conceptos DDD.<br />3, Conceptos SOLID."
        },
    ],
    buildSummary: {
        generatedTitle: "Number of Microservices Generated",
        generatedAmount: "136,452",
        savingsTitle: "saved on development",
        savingsAmount: "1,136,452"
    }
}

export default landingData;